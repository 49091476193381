// request.js
import axios from 'axios'
import ElementUI from 'element-ui';
import store from '../store/store.js'
import router from '../router'
import qs from 'qs'
import {
	baseURL
} from './base.js'
import {
	showLoading,
	hideLoading
} from './loading.js';
// 创建 axios 实例
let baseUrl = ''
let baseur = ''
let Gene_Key = 0
if (process.env.NODE_ENV === 'production') {
	baseUrl = baseURL
} else if (process.env.NODE_ENV === 'development') {
	baseUrl = baseURL
}
const service = axios.create({
	baseURL: baseUrl,
	// 是否跨站点访问控制请求
	withCredentials: false,
	timeout: 60000,
	// headers: {
	// 	// 'Content-Type': 'application/json',
	// 	'X-Requested-With': 'XMLHttpRequest'
	// }
})

// 请求拦截器
service.interceptors.request.use((config) => {
	// console.log('请求0', config)
	// config.timeout = 50000
	if(config.url=='Task/AddData_Tool'){
		config.baseURL = "https://coldverify.coldbaozhida.com/api/"
	}
	if (config.url === "/GenerateReport" || config.url === "/Data/Device_Sensor_Data_More_ColdVerify/Data/Device_Sensor_Data_More_ColdVerify" || config.url === "GenerateReport_Key"
		) { //针对报告生成的提交按钮在url不带/api
		config.baseURL = "https://coldverify.baozhida.cn"
	}
	// console.log('请求',config)
	if (config.url === "/TaskData/Add" || config.url === "/TaskData/List") { //针对报告生成的提交按钮在url不带/api
		hideLoading(); //关闭加载中
		baseur = config.url
	} else if (config.url === '/TaskData/AddS') { //导入数据的地方不显示加载
		// hideLoading(); //关闭加载中
		baseur = config.url
	} else {
		if(config.url === 'GenerateReport_Key'){
			Gene_Key = 1
		}else{
			Gene_Key = 0
		}
		showLoading(); //打开加载中
		baseur = config.url
	}
	var token = store.state.LoginInfo.token;
	config.data.User_tokey = token //token也参数的形式带过去
	config.data = qs.stringify(config.data)
	// config.headers['Authorization'] = token // 让每个请求携带自定义token  //设置请求头
	return config
}, (error) => {
	// 错误抛到业务代码
	error.data = {}
	return Promise.resolve(error)
})
// 响应拦截器
service.interceptors.response.use((response) => {
	// console.log('响应response',response)
	showStatus(response.data)
	return response
}, (error) => {
	// 错误抛到业务代码
	ElementUI.Message({
		message: '请求超时或服务器异常，请检查网络或联系管理员！',
		type: 'error',
		// duration: '1000'
	})
	error.data = {}
	if (baseur != "/TaskData/Add") {
		// console.log('-------------->不等于这个路基')
	} else {
		ElementUI.Message({
			message: '请求超时或服务器异常，请检查网络或联系管理员！',
			type: 'error',
			// duration: '1000'
		})
	}
	hideLoading()
	return Promise.resolve(error)
})

const showStatus = (status) => {
	Gene_Key===1 && status.Data===2?showLoading():hideLoading(Gene_Key)
	switch (status.Code) {
		case 200:
			break
		case 202:
			ElementUI.Message({
				message: '账号密码错误',
				type: 'error'
			})
			break
		case 302:
			ElementUI.Message({
				message: status.Msg + '[' + status.Code + ']',
				type: 'error'
			})
			store.commit('Login', {
				username: '',
				password: '',
				token: '',
				checked: false
			})
			// router.push('/')
			router.push({
				path: this.$route.query.redirect || '/'
			})
			break
		case 400:
			ElementUI.Message({
				message: '网络请求错误[' + status.Code + ']',
				type: 'error'
			})
			break
		case 401:
			ElementUI.Message({
				message: '未授权，请重新登录[' + status.Code + ']',
				type: 'error'
			})
			break
		case 403:
			ElementUI.Message({
				message: '拒绝访问[' + status.Code + ']',
				type: 'error'
			})
			break
		case 404:
			ElementUI.Message({
				message: '请求出错[' + status.Code + ']',
				type: 'error'
			})
			break
		case 500:
			ElementUI.Message({
				message: '服务器错误[' + status.Code + ']',
				type: 'error'
			})
			break
		case 501:
			ElementUI.Message({
				message: '服务未实现[' + status.Code + ']',
				type: 'error'
			})
			break
		case 502:
			ElementUI.Message({
				message: '网络错误[' + status.Code + ']',
				type: 'error'
			})
			break
		default:
			// message = `连接出错(${status})!`
			console.log('500',status.Msg)
			ElementUI.Message({
				message: status.Msg + '[' + status.Code + ']',
				type: 'error'
			});
	}
}

export {
	service as axios
}
